import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withGoogleSheets } from 'react-db-google-sheets';
import LinkList from './LinkList';


const SingleSheet = (props) => {
  const [ linkys, setLinkys ] = useState([]);

  /*const links = [
    { href : '#',
      text : 'Link 1',
      imgUrl : 'https://via.placeholder.com/500'
    },
    { href : '#',
      text : 'Link 2'},
    { href : '#',
      text : 'Link 3',
      imgUrl : 'https://via.placeholder.com/500'},
    { href : '#',
      text : 'Link 4'},
  ];*/

  //const [total, setTotal ] = useState(0);

  let lionks = props.db.Lionks;
  
  useEffect( () => {
    setLinkys(lionks);
  }, [lionks]); 

  return (
    <div>
        <LinkList links={linkys} />
    </div>)
};

SingleSheet.propTypes = {
  db: PropTypes.shape({
    samplesheet: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default withGoogleSheets('Lionks')(SingleSheet);