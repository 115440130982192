function Link( { href = '#', text = 'sup', imgUrl = false} ) {
  const img = imgUrl ? <img src={imgUrl} alt={text} className="link-img" /> : '';

  return (
    <li className="link">
        <a href={href}>{img}<span>{text}</span></a>
    </li>
  );
}

export default Link;
