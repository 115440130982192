import React from 'react';

import './App.css';

import GoogleSheetsProvider from 'react-db-google-sheets';

import './components/LinkLoader';
import LinkLoader from './components/LinkLoader';

function App() {

  /*const links = [
    { href : '#',
      text : 'Link 1',
      imgUrl : 'https://via.placeholder.com/500'
    },
    { href : '#',
      text : 'Link 2'},
    { href : '#',
      text : 'Link 3',
      imgUrl : 'https://via.placeholder.com/500'},
    { href : '#',
      text : 'Link 4'},
  ];*/


  return (
    <div className="App">
      <header className="App-header">
        <h1 className="text-logo"><span>josh</span>wayman</h1>
        <p>
          Hey, here are some links I like.
        </p>

        <GoogleSheetsProvider>
          <LinkLoader />
        </GoogleSheetsProvider>
      </header>
      <footer>
        <a href="https://beech.agency">beech.agency</a>
      </footer>
    </div>
  );
}

export default App;
