import Link from './Link';

function LinkList( { children, links = [ { href : '#', text : 'Nothing to see here' } ] }) {
    const output = links.map( link => {
        return <Link href={link.href} text={link.text} imgUrl={link.imgUrl} />
    });

    return (
      <ul className="linkList">
          {output}
      </ul>
    );
  }
  
  export default LinkList;
  